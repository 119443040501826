import withLoading from "./with_loading";

export const isEmpty = value => {
  if (typeof value === undefined || value === null || value === "") return true;
  return typeof value === "object" && !Object.keys(value).length;
};

export const I18n = {
  visible: "Visível",
  hidden: "Oculto",
  project: "Projeto",
  service: "Serviço"
};

export { withLoading };
