class Storage {
  static async get(args = "") {
    return window.localStorage.getItem(args);
  }

  static async set(key = "", value) {
    value = typeof value === "string" ? value : JSON.stringify(value);
    return window.localStorage.setItem(key, value);
  }

  static async clear() {
    return window.localStorage.clear();
  }
}

export default Storage;
