import React from "react";
import { Link } from "react-router-dom";
import { Icon, Row, Col, Empty, Loading } from "../../components";
import { isEmpty, I18n } from "../../helpers";

export default ({ objects, handleOnModal, handleOnDelete, busy }) => {
  if (isEmpty(objects) && busy) return <Loading />

  if (isEmpty(objects)) return <Empty />;

  return (
    <Row>
      {objects.map(e => (
        <Col col={12} md={4} key={e.id}>
          <div className="card mb-4 animated bounceIn">
            <div className="card-body">
              <div style={{
                width: '100%', height: 128,
                backgroundImage: `url(${e.attributes.image})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center center",
                borderRadius: 4, marginBottom: "0.5em"
              }}></div>
              <strong className="d-block text-blue text-uppercase text-truncate">
                {e.attributes.title}
              </strong>
              <Row>
                <Col col={6}>
                  <small>
                    <Icon className="mdi-calendar text-secondary mr-2" />
                    {e.attributes.created_at}
                  </small>
                </Col>
                <Col col={6} className="d-flex justify-content-end">
                  <div>
                    <span
                      className={`badge badge-pill mr-3 ${
                        e.attributes.type === "project"
                          ? "badge-primary"
                          : "badge-info"
                      }`}
                    >
                      {I18n[e.attributes.type]}
                    </span>
                    <span
                      className={`badge badge-pill ${
                        e.attributes.status === "visible"
                          ? "badge-success"
                          : "badge-secondary"
                      }`}
                    >
                      {I18n[e.attributes.status]}
                    </span>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="card-footer">
              <Row>
                <Col col={6}>
                  <Link
                    to={`/sliders/${e.id}/edit`}
                    onClick={handleOnModal}
                    data-toggle="modal"
                    data-target="#modal"
                    className="mr-3"
                    data-id={e.id}
                  >
                    <Icon className="mdi-pencil text-blue" data-id={e.id} />
                  </Link>
                  <Link
                    to={`/sliders/${e.id}/delete`}
                    onClick={handleOnDelete}
                    data-id={e.id}
                    data-toggle="modal"
                  >
                    <Icon className="mdi-delete text-danger" data-id={e.id} />
                  </Link>
                </Col>
                <Col col={6} className="d-flex justify-content-end">
                  <div className="text-truncate">
                    <small>{e.attributes.category}</small>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      ))}
    </Row>
  );
};
