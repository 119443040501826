import React from "react";
import PropTypes from "prop-types";
import _ from "styled-components";

const FileInput = ({ source, index, height, onChange }) => {
  const id = `${Math.random()
    .toString(36)
    .substring(2)}`;

  return (
    <Wrapper htmlFor={id} source={source} height={height}>
      <Input type="file" id={id} data-index={index} onChange={onChange} />
    </Wrapper>
  );
};

FileInput.defaultProps = {
  height: 96
};

FileInput.propTypes = {
  index: PropTypes.any.isRequired,
  source: PropTypes.string,
  height: PropTypes.number,
  onChange: PropTypes.func.isRequired
};

const Wrapper = _.label`
  width: 100%;
  height: ${({ height }) => height}px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  overflow: hidden;
  background-image: url(${({ source }) => source ? source : require("../../assets/camera.png")});
  background-size: ${({ source }) => source ? "cover" : "none"};
  background-repeat: no-repeat;
  background-position: center center;

  &:hover {
    cursor: pointer;
    border-color: #a8c85a;
  }
`;

const Input = _.input`
  opacity: 0;
`;

export default FileInput;
