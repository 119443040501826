import React from "react";
import {
  Notification,
  Form,
  Image,
  TextInput,
  Submit,
  Row,
  Col
} from "../../components";

const Page = props => (
  <Form onSubmit={props.handleOnSubmit} className="d-flex" autocomplete="off">
    <div className="sign-in-box animated bounceInUp">
      <center>
        <Image
          src={require("../../assets/logo.png")}
          alt="Engelar"
          className="w-75 mb-4"
        />
      </center>
      <Row>
        <Col col={12}>
          <TextInput
            name="username"
            placeholder="E-mail"
            type="email"
            value={props.params.username}
            onChange={props.handleOnChange}
          />
        </Col>
        <Col col={7}>
          <TextInput
            name="password"
            placeholder="******"
            type="password"
            value={props.params.password}
            onChange={props.handleOnChange}
          />
        </Col>
        <Col col={5}>
          <Submit
            title="Login"
            busy={props.busy}
            className="btn btn-primary btn-block"
          />
        </Col>
      </Row>
      <Notification {...props.notification} />
      <p>
        <small className="text-secondary">
          Este é um acesso restrito, somente pessoal autorizado possui o acesso.{" "}
          <a href="http://www.engelar.eng.br/">Voltar ao website</a>.
        </small>
      </p>
      <p>
        <small>
          &copy; Engelar, 2019.{" "}
          <a
            href="https://www.linkedin.com/in/bsilva0x87"
            className="text-dark"
            target="_blank"
            rel="noopener noreferrer"
            title="bsilva0x87"
          >
            bsilva0x87
          </a>
          .
        </small>
      </p>
    </div>
  </Form>
);

export default Page;
