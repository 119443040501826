import { Request } from ".";

class Category {
  constructor(attrs = {}) {
    this.attributes = { ...attrs };
  }

  static async all(page = 1) {
    return await Request.get(`categories?page=${page}`);
  }

  static async status(status = `visible`) {
    return await Request.get(`categories/status/${status}`);
  }

  async create() {
    return await Request.post("categories", { ...this.attributes });
  }

  static async find(id = "") {
    return await Request.get(`categories/${id}`);
  }

  async update(id = ``) {
    return await Request.put(`categories/${id}`, { ...this.attributes });
  }

  static async destroy(id = "") {
    return await Request.delete(`categories/${id}`);
  }
}

Category.schema = {
  name: "",
  description: "",
  status: "visible"
};

export default Category;
