import { Request } from ".";

class Slider {
  constructor(attrs = {}) {
    this.attributes = { ...attrs };
  }

  static async all(page = 1) {
    return await Request.get(`sliders?page=${page}`);
  }

  async create() {
    return await Request.post("sliders", { ...this.attributes });
  }

  static async find(id = "") {
    return await Request.get(`sliders/${id}`);
  }

  async update(id = ``) {
    return await Request.put(`sliders/${id}`, { ...this.attributes });
  }

  static async destroy(id = "") {
    return await Request.delete(`sliders/${id}`);
  }
}

Slider.schema = {
  title: "",
  description: "",
  image_id: "",
  status: "visible"
};

export default Slider;
