import React from "react";
import PropType from "prop-types";

const Button = ({ title, onClick, hidden, type, busy }) => {
  if (hidden) return null;

  return (
    <button className={`btn btn-${type}`} disabled={busy} onClick={onClick}>
      {busy ? "Aguarde..." : title}
    </button>
  )
};

Button.defaultProps = {
  busy: false,
  type: "primary",
  hidden: false
};

Button.propTypes = {
  title: PropType.string.isRequired,
  action: PropType.func,
  hidden: PropType.bool
};

export default Button;
