import { Request, Storage } from "./index";

class Session {
  static async auth(args = {}) {
    return await Request.post("sessions", args);
  }

  static async check() {
    return await Request.get("sessions");
  }

  static async clear() {
    return await Storage.clear();
  }
}

export default Session;
