import React from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Layout,
  Title,
  TextInput,
  TextArea,
  Modal,
  RadioButton,
  Select,
  Row,
  Col,
  Notification,
  FileInput
} from "../../components";

import List from "./_list";

const Page = props => (
  <Layout>
    <Container>
      <Row>
        <Col col={6}>
          <div className="animated bounceInLeft">
            <Title>Trabalhos ({props.objects.length})</Title>
            <p className="text-secondary mb-4 text-truncate">
              Nesta área você poderá gerenciar seus trabalhos.
            </p>
          </div>
        </Col>
        <Col col={6}>
          <div className="d-flex justify-content-end">
            <Link
              to="/works/new"
              onClick={props.handleOnModal}
              className="btn btn-primary mt-3 mt-md-0 animated bounceInRight"
              data-toggle="modal"
              data-target="#modal">
              Novo Trabalho
            </Link>
          </div>
        </Col>
      </Row>
      <List {...props} />
      <Modal busy={props.busy} large onSubmit={props.handleOnSubmit}>
        <h2 className="text-2 mb-0">Informações Básicas</h2>
        <span className="text-secondary">Preencha os campos abaixo.</span>
        <Notification type="danger" message={props.error.message} />
        <div className="d-flex-inline mt-2 mb-2">
          <RadioButton
            label="Produto"
            name="type"
            value="product"
            checked={props.params.type === "product"}
            onChange={props.handleOnChange}
          />
          <RadioButton
            label="Portifólio"
            name="type"
            value="portfolio"
            checked={props.params.type === "portfolio"}
            onChange={props.handleOnChange}
          />
        </div>
        <TextInput
          name="title"
          value={props.params.title}
          placeholder="Título do trabalho"
          disabled={props.busy}
          onChange={props.handleOnChange}
        />
        <TextArea
          name="description"
          value={props.params.description}
          placeholder="Descrição completa..."
          disabled={props.busy}
          onChange={props.handleOnChange}
        />
        <Row>
          <Col col={12} md={6}>
            <Select
              name="category_id"
              options={props.options}
              value={props.params.category_id}
              onChange={props.handleOnChange}
            />
          </Col>
        </Row>

        <h2 className="text-2 mb-0">Galeria de imagens</h2>
        <span className="text-secondary">
          O tamanho recomendado para cada imagem é <strong>800x600</strong> pixels.
        </span>
        <div className="mt-3">
          <Row>
            {
              [0, 1, 2, 3].map(e => (
                <Col col={3} key={e}>
                  <FileInput
                    index={e}
                    source={props.params.images[e] && props.params.images[e].attributes.url}
                    onChange={props.handleOnUpload}
                  />
                </Col>
              ))
            }
          </Row>
        </div>

        <div className="d-flex-inline">
          <RadioButton
            label="Visível"
            name="status"
            value="visible"
            checked={props.params.status === "visible"}
            onChange={props.handleOnChange}
          />
          <RadioButton
            label="Oculto"
            name="status"
            value="hidden"
            checked={props.params.status === "hidden"}
            onChange={props.handleOnChange}
          />
        </div>
      </Modal>
    </Container>
  </Layout>
);

export default Page;
