import { Request } from ".";

class Candidate {
  static async all(page = 1) {
    return await Request.get(`candidates?page=${page}`);
  }

  static async find(id = "") {
    return await Request.get(`candidates/${id}`);
  }

  async create(params = {}) {
    return await Request.public.post("candidates", params);
  }

  static async destroy(id = "") {
    return await Request.delete(`candidates/${id}`);
  }
}

export default Candidate;
