import React, { useEffect, useState } from "react";
import { Category } from "../models";
import { CategoriesPage } from "../views";

const CategoriesController = () => {
  const [objects, setObjects] = useState([]);
  const [params, setParams] = useState({ ...Category.schema });
  const [error, setError] = useState({});
  const [busy, setBusy] = useState(true);

  useEffect(() => { handleOnLoad() }, []);

  const handleOnLoad = async () => {
    try {
      const { data } = await Category.all();
      setObjects(data);
    } catch (err) {
      console.log(err);
    } finally {
      setBusy(false);
    }
  };

  const handleOnChange = e =>
    setParams({ ...params, [e.target.name]: e.target.value });

  const handleOnModal = async (e) => {
    if (!e.target.dataset.id)
      return setParams({ ...Category.schema });

    try {
      setBusy(true);
      const { data } = await Category.find(e.target.dataset.id);
      setParams({ id: data.id, ...data.attributes });
    } catch (err) {
      console.log(err)
    } finally {
      setError({}); setBusy(false);
    }
  };

  const handleOnDelete = async (e) => {
    await Category.destroy(e.target.dataset.id);
    return handleOnLoad();
  }

  const handleOnSubmit = async (e) => {
    e.preventDefault();

    try {
      setBusy(true);
      const object = new Category(params);
      if (params.id)
        await object.update(params.id);
      else
        await object.create();
      window.$('#modal').modal('toggle');
      return handleOnLoad();
    } catch (err) {
      setError({...err.data });
    } finally {
      setBusy(false);
    }
  };

  const viewProps = {
    objects,
    params,
    busy,
    error,
    handleOnChange,
    handleOnModal,
    handleOnDelete,
    handleOnSubmit
  };

  return <CategoriesPage {...viewProps} />;
};

export default CategoriesController;
