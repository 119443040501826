import React, { useEffect } from "react";
import { Storage } from "../models";

const LogoutController = ({ history }) => {
  useEffect(() => {
    Storage.clear();
    history.push(`/session`);
  }, [history]);

  return <span>Aguarde...</span>;
};

export default LogoutController;
