import React from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Layout,
  Title,
  TextInput,
  Modal,
  RadioButton,
  Row,
  Col,
  Notification
} from "../../components";

import List from "./_list";

const Page = props => (
  <Layout>
    <Container>
      <Row>
        <Col col={6}>
          <div className="animated bounceInLeft">
            <Title>Categorias ({props.objects.length})</Title>
            <p className="text-secondary mb-4 text-truncate">
              Nesta área você poderá gerenciar as categorias.
            </p>
          </div>
        </Col>
        <Col col={6}>
          <div className="d-flex justify-content-end">
            <Link
              to="/categories/new"
              onClick={props.handleOnModal}
              className="btn btn-primary mt-3 mt-md-0 animated bounceInRight"
              data-toggle="modal"
              data-target="#modal">
              Nova Categoria
            </Link>
          </div>
        </Col>
      </Row>
      <List {...props} />
      <Modal busy={props.busy} onSubmit={props.handleOnSubmit}>
        <h2 className="text-2 mb-0">Informações Básicas</h2>
        <span className="text-secondary">Preencha os campos abaixo.</span>
        <Notification type="danger" message={props.error.message} />
        <div className="mt-3">
          <TextInput
            name="name"
            value={props.params.name}
            placeholder="Nome da categoria"
            disabled={props.busy}
            onChange={props.handleOnChange}
          />
          <TextInput
            name="description"
            value={props.params.description}
            placeholder="Descrição"
            disabled={props.busy}
            onChange={props.handleOnChange}
          />
          <div className="d-flex-inline mt-2 mb-2">
            <RadioButton
              label="Visível"
              name="status"
              value="visible"
              checked={props.params.status === "visible"}
              onChange={props.handleOnChange}
            />
            <RadioButton
              label="Oculta"
              name="status"
              value="hidden"
              checked={props.params.status === "hidden"}
              onChange={props.handleOnChange}
            />
          </div>
        </div>
      </Modal>
    </Container>
  </Layout>
);

export default Page;
