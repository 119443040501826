import React from "react";

const Card = props => (
  <div {...props}>
    <div className="card-body">{props.children}</div>
  </div>
);

Card.defaultProps = {
  className: "card mb-2"
};

export default Card;
