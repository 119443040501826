import React from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Layout,
  Title,
  TextInput,
  Modal,
  RadioButton,
  Row,
  Col,
  Notification,
  FileInput
} from "../../components";

import List from "./_list";

const Page = props => (
  <Layout>
    <Container>
      <Row>
        <Col col={6}>
          <div className="animated bounceInLeft">
            <Title>Sliders ({props.objects.length})</Title>
            <p className="text-secondary mb-4 text-truncate">
              Nesta área você poderá gerenciar os sliders da página inicial.
            </p>
          </div>
        </Col>
        <Col col={6}>
          <div className="d-flex justify-content-end animated bounceInRight">
            <Link
              to="/sliders/new"
              onClick={props.handleOnModal}
              className="btn btn-primary mt-3 mt-md-0"
              data-toggle="modal"
              data-target="#modal">
              Novo Slider
            </Link>
          </div>
        </Col>
      </Row>
      <List {...props} />
      <Modal busy={props.busy} onSubmit={props.handleOnSubmit}>
        <h2 className="text-2 mb-0">Informações Básicas</h2>
        <span className="text-secondary">Preencha os campos abaixo.</span>
        <Notification {...props.notification} />
        <div className="mt-3">
          <TextInput
            name="title"
            value={props.params.title}
            placeholder="Título do slider"
            disabled={props.busy}
            onChange={props.handleOnChange}
          />
          <TextInput
            name="description"
            value={props.params.description}
            placeholder="Descrição"
            disabled={props.busy}
            onChange={props.handleOnChange}
          />

          <h2 className="text-2 mb-0">Imagem do slider</h2>
          <span className="text-secondary">
            O tamanho recomendado para a imagem é <strong>1280x500</strong> pixels.
          </span>
          <div className="mt-2 mb-2">
            <FileInput
              index="0"
              height={144}
              source={props.params.image && props.params.image.attributes.url}
              onChange={props.handleOnUpload}
            />
          </div>
          <div className="d-flex-inline">
            <RadioButton
              label="Visível"
              name="status"
              value="visible"
              checked={props.params.status === "visible"}
              onChange={props.handleOnChange}
            />
            <RadioButton
              label="Oculto"
              name="status"
              value="hidden"
              checked={props.params.status === "hidden"}
              onChange={props.handleOnChange}
            />
          </div>
        </div>
      </Modal>
    </Container>
  </Layout>
);

export default Page;
