import React from "react";

export default () => (
  <center>
    <img
      style={{ width: 256 }}
      src={require("../../assets/loader.gif")}
      alt="Loading..."
    />
  </center>
);
