import React from "react";
import PropTypes from "prop-types";
import { Button } from "../";

const Modal = ({ busy, onSubmit, large, children }) => (
  <div id="modal" className="modal fade" tabIndex="-1" role="dialog">
    <div
      className={`modal-dialog ${large ? "modal-lg" : null}`}
      role="document">
      <div className="modal-content">
        <div className="modal-body">{children}</div>
        <div className="modal-footer">
          <Button
            title="Salvar!"
            busy={busy}
            onClick={onSubmit}
            hidden={!onSubmit}
          />
          <button
            type="button"
            className={`btn ${!onSubmit ? "btn-secondary" : "btn-link"}`}
            data-dismiss="modal">
            Cancelar
          </button>
        </div>
      </div>
    </div>
  </div>
);

Modal.propTypes = {
  onSubmit: PropTypes.func,
  busy: PropTypes.bool,
  large: PropTypes.bool
};

export default Modal;
