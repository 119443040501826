import React from "react";
import { Layout, Title, Container, Row, Col, Shortcut } from "../../components";

const Page = () => (
  <Layout>
    <Container>
      <Row>
        <Col col={12} sm={12} md={9} lg={6}>
          <div className="animated bounceInLeft">
            <Title>Dashboard</Title>
            <p className="text-secondary mb-4">
              Esta é a área inicial do painel administrativo do seu website. Para
              continuar, selecione uma das opções abaixo:
            </p>
          </div>
          <Row>
            <Col col={12} md={4}>
              <Shortcut
                icon="mdi-file-document-box-check-outline"
                title="Trabalhos"
                to="/works"
              />
            </Col>
            <Col col={12} md={4}>
              <Shortcut
                icon="mdi-map-marker-circle"
                title="Categorias"
                to="/categories"
              />
            </Col>
            <Col col={6} md={4}>
              <Shortcut
                icon="mdi-image-outline"
                title="Sliders"
                to="/sliders"
              />
            </Col>
            <Col col={6} md={4}>
              <Shortcut icon="mdi-account" title="Contatos" to="/contacts" />
            </Col>
            <Col col={6} md={4}>
              <Shortcut
                icon="mdi-account-group"
                title="Candidatos"
                to="/candidates"
              />
            </Col>
            <Col col={6} md={4}>
              <Shortcut
                icon="mdi-card-bulleted-outline"
                title="Geral"
                to="/settings"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  </Layout>
);

export default Page;
