import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { SessionContext } from "../../contexts";
import { Session, Storage } from "../../models";

class SessionProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: null,
      dispatch: (action, token) => this.setState(reducer(action, token))
    };
  }

  componentDidMount = async () => {
    let token;

    try {
      token = await Storage.get(`@engelar:token`);
      if (!token) throw Error(`There's no session token!`);

      token = await Session.check();
      this.setState({ token });
    } catch {
      Storage.clear();
      return this.props.history.push("/session");
    }
  };

  render = () => (
    <SessionContext.Provider value={this.state}>
      {this.props.children}
    </SessionContext.Provider>
  );
}

const reducer = (action, token) => {
  if (action === "TOKEN") {
    Storage.set(`@engelar:token`, token);
    return { token };
  }

  if (action === "LOGOUT") {
    Storage.clear();
    return { token: null };
  }
};

export default withRouter(SessionProvider);
