import React from "react";
import {
  Container,
  Layout,
  Title,
  Row,
  Col,
  Notification,
  TextInput,
  TextArea,
  Button
} from "../../components";

const style = {
  color: "#333",
  textDecoration: "none"
};

const defaultMessage =
  "A nossa equipe já recebeu a sua mensagem. Em breve entraremos em contato.";

const Page = props => (
  <Layout>
    <Container>
      <div className="sticky-top" style={{ backgroundColor: "white" }}>
        <Row>
          <Col col={6}>
            <div className="animated bounceInLeft">
              <Title>Configurações Gerais</Title>
              <p className="text-secondary text-truncate">
                Nesta área você poderá gerenciar as configurações do website.
              </p>
            </div>
          </Col>
          <Col col={6}>
            <div className="d-flex justify-content-end pt-3 animated bounceInRight">
              <Button
                title="PUBLICAR!"
                busy={props.busy}
                onClick={props.handleOnSubmit}
              />
            </div>
          </Col>
        </Row>
      </div>
      <Notification {...props.notification} />
      <Row>
        <Col col={12} md={7}>
          <div className="animated bounceInUp">
            <div className="mb-3">
              <h2 className="text-2 mb-0">Informações Básicas</h2>
              <span className="text-secondary">
                Informações gerais sobre dados do website.
              </span>
            </div>
            <div id="basic">
              <Row>
                <Col col={12}>
                  <TextInput
                    name="company"
                    value={props.params.company}
                    placeholder="Nome da Empresa"
                    disabled={props.busy}
                    onChange={props.handleOnChange}
                  />
                </Col>
                <Col col={12}>
                  <TextInput
                    name="address"
                    value={props.params.address || ""}
                    placeholder="Endereço completo"
                    disabled={props.busy}
                    onChange={props.handleOnChange}
                  />
                </Col>
                <Col col={6}>
                  <TextInput
                    name="phone"
                    value={props.params.phone}
                    placeholder="Telefone"
                    data-mask="phone"
                    disabled={props.busy}
                    onChange={props.handleOnChange}
                  />
                </Col>
                <Col col={6}>
                  <TextInput
                    name="mobile"
                    value={props.params.mobile}
                    placeholder="Celular/WhatsApp"
                    data-mask="phone"
                    disabled={props.busy}
                    onChange={props.handleOnChange}
                  />
                </Col>
              </Row>
            </div>
            <div className="mb-3">
              <a data-toggle="collapse" href="#about" {...{ style }}>
                <h2 className="text-2 mb-0">Resumo sobre a Empresa</h2>
                <span className="text-secondary">
                  Defina mensagem de apresentação da empresa no website.
                </span>
              </a>
            </div>
            <div className="collapse" id="about">
              <TextArea
                name="about"
                placeholder="Ex.: Há mais de 6 anos no mercado, a Engelar oferece o pacote completo para sua cobertura..."
                disabled={props.busy}
                value={props.params.about}
                onChange={props.handleOnChange}
              />
              <TextInput
                name="facebook"
                value={props.params.facebook}
                placeholder="https://facebook.com/engelar"
                disabled={props.busy}
                onChange={props.handleOnChange}
              />
              <TextInput
                name="instagram"
                value={props.params.instagram}
                placeholder="https://instagram.com/engelar"
                disabled={props.busy}
                onChange={props.handleOnChange}
              />
              <TextInput
                name="linkedin"
                value={props.params.linkedin}
                placeholder="https://linkedin.com/engelar"
                disabled={props.busy}
                onChange={props.handleOnChange}
              />
            </div>
            <div className="mb-3">
              <a data-toggle="collapse" href="#email_autoreply" {...{ style }}>
                <h2 className="text-2 mb-0">Auto-resposta: Fale Conosco</h2>
                <span className="text-secondary">
                  Defina as configurações de auto-resposta para quando um usuário
                  entrar em contato pelo website.
                </span>
              </a>
            </div>
            <div className="collapse" id="email_autoreply">
              <TextInput
                name="email"
                value={props.params.email}
                placeholder="E-mail principal"
                disabled={props.busy}
                onChange={props.handleOnChange}
              />
              <TextArea
                name="email_autoreply"
                placeholder={`Ex.: ${defaultMessage}`}
                disabled={props.busy}
                value={props.params.email_autoreply || ""}
                onChange={props.handleOnChange}
              />
            </div>
            <div className="mb-3">
              <a data-toggle="collapse" href="#jobs_autoreply" {...{ style }}>
                <h2 className="text-2 mb-0">Auto-resposta: Trabalhe Conosco</h2>
                <span className="text-secondary">
                  Defina as configurações de auto-resposta para quando um usuário
                  se candidatar pelo website.
                </span>
              </a>
            </div>
            <div className="collapse" id="jobs_autoreply">
              <TextInput
                name="email_for_jobs"
                value={props.params.email_for_jobs}
                placeholder="E-mail para candidatos"
                disabled={props.busy}
                onChange={props.handleOnChange}
              />
              <TextArea
                name="jobs_autoreply"
                placeholder={`Ex.: ${defaultMessage}`}
                disabled={props.busy}
                value={props.params.jobs_autoreply || ""}
                onChange={props.handleOnChange}
              />
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  </Layout>
);

export default Page;
