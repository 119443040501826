import { Request } from ".";

class Work {
  constructor(attrs = {}) {
    this.attributes = { ...attrs };
  }

  static async all(page = 1) {
    return await Request.get(`works?page=${page}`);
  }

  async create() {
    return await Request.post("works", { ...this.attributes });
  }

  static async find(id = "") {
    return await Request.get(`works/${id}`);
  }

  async update(id = ``) {
    return await Request.put(`works/${id}`, { ...this.attributes });
  }

  static async destroy(id = "") {
    return await Request.delete(`works/${id}`);
  }
}

Work.schema = {
  description: "",
  status: "visible",
  title: "",
  category_id: "",
  type: "portfolio",
  images: []
};

export default Work;
