import React from "react";
import PropTypes from "prop-types";

const Select = ({ name, options, value, onChange }) => (
  <select className="form-control mb-3" name={name} value={value} onChange={onChange}>
    <option value="">Selecione...</option>
    {
      options && options.map(e => (
        <option
          key={`${e.id}`}
          value={e.id}>
          {e.label}
        </option>
      ))
    }
  </select>
);

Select.defaultProps = {
  options: []
};

Select.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired
};

export default Select;
