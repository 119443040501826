import React from "react";
import { Link } from "react-router-dom";
import _ from "styled-components";
import Icon from "../icon";

const Shortcut = ({ title, icon, to = "/" }) => (
  <Button to={to}>
    <div className="card mb-4 animated bounceIn">
      <div className="card-body text-truncate">
        <Icon className={icon} />
        <Title>{title}</Title>
      </div>
    </div>
  </Button>
);

const Button = _(Link)`
  display: block;
  text-align: center;
  text-transform: uppercase;

  &:hover {
    text-decoration: none;
  }

  i {
    font-size: 3rem;
    line-height: 3.5rem;
    color: #384e70;
    display: block;
  }
`;

const Title = _.strong`
  font-size: 1rem;
  color: #384e70;
`;

export default Shortcut;
