import React, { Component } from "react";
import { SessionContext } from "../../contexts";

class SessionConsumer extends Component {
  render = () => (
    <SessionContext.Consumer>{this.props.children}</SessionContext.Consumer>
  );
}

SessionConsumer.contextType = SessionContext;

export default SessionConsumer;
