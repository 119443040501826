import React from "react";
import PropTypes from "prop-types";

const Empty = ({ title, message }) => (
  <div className="jumbotron text-center animated shake">
    <h1 className="text-5 text-blue">{title}</h1>
    <p className="text-1">{message}</p>
  </div>
);

Empty.defaultProps = {
  title: "Não há registros para mostrar!",
  message:
    "Você pode adicionar um novo registro, clicando no botão localizado no canto superior direito desta tela."
};

Empty.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string
};

export default Empty;
