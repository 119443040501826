import { Request } from ".";

class Image {
  constructor(attrs = {}) {
    this.attributes = { ...attrs };
  }

  async create() {
    return await Request.post("images", { ...this.attributes });
  }
}

Image.schema = {
  type: "",
  source: ""
};

export default Image;
