import React, { useState } from "react";
import { Session, Storage } from "../models";
import { SessionPage } from "../views";

const SessionController = ({ history }) => {
  const [params, setParams] = useState({
    username: ``,
    password: ``
  });

  const [notification, setNotification] = useState("");
  const [busy, setBusy] = useState(false);

  const handleOnChange = e =>
    setParams({ ...params, [e.target.name]: e.target.value });

  const handleOnSubmit = async e => {
    e.preventDefault();

    try {
      setBusy(true); setNotification({});
      const { data: { token }} = await Session.auth(params);
      Storage.set(`@engelar:token`, token);
      history.push(`/`);
    } catch (err) {
      setBusy(false);
      setNotification({
        type: `danger`,
        message: `Usuário e/ou senha inválida.`
      });
    }
  };

  const viewProps = {
    params,
    busy,
    notification,
    handleOnChange,
    handleOnSubmit
  };

  return <SessionPage {...viewProps} />;
};

export default SessionController;
