import React from "react";
import { Link } from "react-router-dom";
import { Icon, Empty, Loading } from "../../components";
import { isEmpty } from "../../helpers";

export default ({ objects, handleOnModal, handleOnDelete, busy }) => {
  if (isEmpty(objects) && busy) return <Loading />

  if (isEmpty(objects))
    return (
      <Empty message="Os candidatos que enviarem seus dados através do formulário de trabalhe conosco, aparecerão nesta área." />
    );

  return (
    <div className="table-responsive animated bounceInUp">
      <table className="table">
        <tbody>
          <tr className="text-uppercase text-blue">
            <th width="5%">ID</th>
            <th>Candidato</th>
            <th width="25%">Telefone</th>
            <th width="15%">Aberto em</th>
            <th width="15%">Enviado em</th>
          </tr>
          {objects.map(e => (
            <tr key={e.id}>
              <td>#{e.id}</td>
              <td className="text-truncate">
                <Link
                  to={`/candidates/${e.id}`}
                  onClick={handleOnModal}
                  data-toggle="modal"
                  data-target="#modal"
                  className={
                    e.attributes.open_at
                      ? `font-weight-normal`
                      : `font-weight-bold`
                  }
                  data-id={e.id}
                >
                  {e.attributes.name}
                </Link>
              </td>
              <td className="text-truncate">
                <Link
                  to={`/candidates/${e.id}`}
                  onClick={handleOnModal}
                  data-toggle="modal"
                  data-target="#modal"
                  className={
                    e.attributes.open_at
                      ? `font-weight-normal`
                      : `font-weight-bold`
                  }
                  data-id={e.id}
                >
                  {e.attributes.phone}
                </Link>
              </td>
              <td>{e.attributes.open_at}</td>
              <td className="relative">
                {e.attributes.created_at}
                <div className="table-actions d-flex justify-content-center align-items-center">
                  <Link
                    to={`/candidates/${e.id}/edit`}
                    onClick={handleOnModal}
                    data-toggle="modal"
                    data-target="#modal"
                    className="mr-3"
                    data-id={e.id}
                  >
                    <Icon className="mdi-pencil text-blue" data-id={e.id} />
                  </Link>
                  <Link
                    to={`/candidates/${e.id}/delete`}
                    onClick={handleOnDelete}
                    data-id={e.id}
                    data-toggle="modal"
                  >
                    <Icon className="mdi-delete text-danger" data-id={e.id} />
                  </Link>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
