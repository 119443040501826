import { Request } from "../models";

class Settings {
  constructor(attrs = {}) {
    this.attributes = attrs;
  }

  static async all() {
    return await Request.get("settings");
  }

  async save() {
    return await Request.post("settings", this.attributes);
  }
}

Settings.schema = {
  company: "",
  about: "",
  email: "",
  email_for_jobs: "",
  phone: "",
  mobile: "",
  address: "",
  email_autoreply: "",
  jobs_autoreply: ""
};

export default Settings;
