import Axios from "axios";
import { Storage } from "./index";
import { host } from "../config";

class Request {
  static async get(path = "") {
    const token = await Storage.get(`@engelar:token`);
    Axios.defaults.headers.common[`Authorization`] = token;

    return Axios.get(`${host}/${path}`)
      .then(response => Promise.resolve(response.data))
      .catch(err => Promise.reject(err.response.data));
  }

  static async post(path = "", params = {}) {
    const token = await Storage.get(`@engelar:token`);
    Axios.defaults.headers.common[`Authorization`] = token;

    return Axios.post(`${host}/${path}`, params)
      .then(response => Promise.resolve(response.data))
      .catch(err => Promise.reject(err.response.data));
  }

  static async put(path = "", params = {}) {
    const token = await Storage.get(`@engelar:token`);
    Axios.defaults.headers.common[`Authorization`] = token;

    return Axios.put(`${host}/${path}`, params)
      .then(response => Promise.resolve(response.data))
      .catch(err => Promise.reject(err.response.data));
  }

  static async delete(path = "") {
    const token = await Storage.get(`@engelar:token`);
    Axios.defaults.headers.common[`Authorization`] = token;

    return Axios.delete(`${host}/${path}`)
      .then(response => Promise.resolve(response.data))
      .catch(err => Promise.reject(err.response.data));
  }
}

Request.public = {
  get: async (path = "") =>
    Axios.get(`${host}/${path}`)
      .then(response => Promise.resolve(response.data))
      .catch(err => Promise.reject(err.response.data)),
  post: async (path = "", params = {}) =>
    Axios.post(`${host}/${path}`, params)
      .then(response => Promise.resolve(response.data))
      .catch(err => Promise.reject(err.response.data))
};

export default Request;
