import React, { useEffect, useState } from "react";
import { Slider, Image } from "../models";
import { SlidersPage } from "../views";

const SlidersController = () => {
  const [objects, setObjects] = useState([]);
  const [params, setParams] = useState({ ...Slider.schema });
  const [notification, setNotification] = useState({});
  const [busy, setBusy] = useState(true);

  useEffect(() => {
    handleOnLoad();
  }, []);

  const handleOnLoad = async () => {
    try {
      const { data } = await Slider.all();
      setObjects(data);
    } catch (err) {
      console.log(err);
    } finally {
      setBusy(false);
    }
  };

  const handleOnChange = e =>
    setParams({ ...params, [e.target.name]: e.target.value });

  const handleOnModal = async e => {
    if (!e.target.dataset.id) return setParams({ ...Slider.schema });

    try {
      setBusy(true);
      const { data, included } = await Slider.find(e.target.dataset.id);
      const image = included.find(e => e.type === "image");
      setParams({ id: data.id, ...data.attributes, image });
    } catch (err) {
      console.log(err);
    } finally {
      setNotification({});
      setBusy(false);
    }
  };

  const handleOnUpload = e => {
    const file = e.target.files && e.target.files[0];
    if (!file) return null;

    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = async e => {
      try {
        setBusy(true);
        setNotification({});

        const object = new Image({ type: "slider", source: e.target.result });
        const { data } = await object.create();
        setParams({ ...params, image: data });
      } catch (err) {
        setNotification({ type: `danger`, ...err.data });
        console.log("FileInput", err);
      } finally {
        setBusy(false);
      }
    };
  };

  const handleOnDelete = async e => {
    await Slider.destroy(e.target.dataset.id);
    return handleOnLoad();
  };

  const handleOnSubmit = async e => {
    e.preventDefault();

    try {
      setBusy(true);
      setNotification({});

      const { image } = params;
      params.image_id = image && image.id;

      const object = new Slider(params);
      if (params.id) await object.update(params.id);
      else await object.create();
      window.$("#modal").modal("toggle");
      return handleOnLoad();
    } catch (err) {
      setNotification({ type: `danger`, ...err.data });
    } finally {
      setBusy(false);
    }
  };

  const viewProps = {
    objects,
    params,
    busy,
    notification,
    handleOnChange,
    handleOnModal,
    handleOnUpload,
    handleOnDelete,
    handleOnSubmit
  };

  return <SlidersPage {...viewProps} />;
};

export default SlidersController;
