import React, { useEffect, useState } from "react";
import { Contact } from "../models";
import { ContactsPage } from "../views";

const ContactsController = () => {
  const [objects, setObjects] = useState([]);
  const [params, setParams] = useState({});
  const [busy, setBusy] = useState(true);

  useEffect(() => {
    handleOnLoad();
  }, []);

  const handleOnLoad = async () => {
    try {
      const { data } = await Contact.all();
      setObjects(data);
    } catch (err) {
      console.log(err);
    } finally {
      setBusy(false);
    }
  };

  const handleOnChange = e =>
    setParams({ ...params, [e.target.name]: e.target.value });

  const handleOnModal = async e => {
    if (!e.target.dataset.id) return setParams({});

    try {
      setBusy(true);
      const { data } = await Contact.find(e.target.dataset.id);
      setParams({ id: data.id, ...data.attributes });
    } catch (err) {
      console.log(err);
    } finally {
      setBusy(false);
    }
  };

  const handleOnDelete = async e => {
    await Contact.destroy(e.target.dataset.id);
    return handleOnLoad();
  };

  const viewProps = {
    objects,
    params,
    busy,
    handleOnChange,
    handleOnModal,
    handleOnDelete
  };

  return <ContactsPage {...viewProps} />;
};

export default ContactsController;
