import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./icons.css";
import "./animate.css";

import { BrowserRouter } from "react-router-dom";
import { Routes } from "./config";
import * as serviceWorker from "./serviceWorker";

const CPanel = () => (
  <BrowserRouter>
    <Routes />
  </BrowserRouter>
);

ReactDOM.render(<CPanel />, document.getElementById("root"));
serviceWorker.unregister();
