import React, { useEffect, useState } from "react";
import { Work, Category, Image } from "../models";
import { WorksPage } from "../views";
import { isEmpty } from "../helpers";

const WorksController = () => {
  const [objects, setObjects] = useState([]);
  const [params, setParams] = useState({ ...Work.schema });
  const [options, setOptions] = useState([]);
  const [error, setError] = useState({});
  const [busy, setBusy] = useState(true);

  useEffect(() => {
    handleOnLoad(); handleOnOptions();
  }, []);

  useEffect(() => { console.table(params) }, [params]);

  const handleOnLoad = async () => {
    try {
      const { data } = await Work.all();
      setObjects(data);
    } catch (err) {
      console.log(err);
    } finally {
      setBusy(false);
    }
  };

  const handleOnOptions = async () => {
    const { data } = await Category.status("visible");
    const options =
      data && data.map(e => ({
        id: e.id,
        label: e.attributes.name
      }));
    setOptions(options);
  };

  const handleOnChange = e =>
    setParams({ ...params, [e.target.name]: e.target.value });

  const handleOnUpload = e => {
    const file = e.target.files && e.target.files[0];
    if (!file) return null;

    const images = params.images;
    const index = e.target.dataset.index;
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = async e => {
      try {
        setBusy(true);
        const object = new Image({ type: "work", source: e.target.result });
        const { data } = await object.create();
        images[index] = data;
        setParams({ ...params, images });
      } catch (err) {
        console.log("FileInput", err);
      } finally {
        setBusy(false);
      }
    };
  };

  const handleOnModal = async e => {
    if (!e.target.dataset.id) return setParams({ ...Work.schema });

    try {
      setBusy(true);
      const { data, included } = await Work.find(e.target.dataset.id);
      const { attributes } = data;

      const images   = included.filter(e => e.type === "image");
      const category = included.find(e => e.type === "category");

      setParams({
        id: data.id, ...attributes, category_id: category && category.id, images
      });
    } catch (err) {
      console.log(err);
    } finally {
      setError({});
      setBusy(false);
    }
  };

  const handleOnDelete = async e => {
    await Work.destroy(e.target.dataset.id);
    return handleOnLoad();
  };

  const handleOnSubmit = async e => {
    e.preventDefault();

    try {
      setBusy(true);

      if (isEmpty(params.category_id))
        params.category_id = null;

      const images = params.images && params.images.map(e => e && e.id)
                                                   .filter(e => e !== null);

      const attrs = { ...params, images };
      const object = new Work(attrs);

      if (params.id) await object.update(params.id);
      else await object.create();

      window.$("#modal").modal("toggle");

      return handleOnLoad();
    } catch (err) {
      setError({ ...err.data });
    } finally {
      setBusy(false);
    }
  };

  const viewProps = {
    objects,
    params,
    busy,
    error,
    options,
    handleOnChange,
    handleOnModal,
    handleOnUpload,
    handleOnDelete,
    handleOnSubmit
  };

  return <WorksPage {...viewProps} />;
};

export default WorksController;
