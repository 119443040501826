import React, { useEffect, useState } from "react";
import VMasker from "vanilla-masker";
import { Settings } from "../models";
import { SettingsPage } from "../views";

const SettingsController = () => {
  const [params, setParams] = useState({ ...Settings.schema });
  const [notification, setNotification] = useState({});
  const [busy, setBusy] = useState(true);

  useEffect(() => {
    handleOnLoad();
  }, []);

  const handleOnLoad = async () => {
    try {
      const { data } = await Settings.all();
      if (data) setParams({ ...data.attributes });
    } catch (err) {
      console.log(err);
    } finally {
      setBusy(false);
    }
  };

  const handleOnChange = e => {
    let input = e.target.name;
    let value = e.target.value;

    /**
     * Apply vanilla masket to input with attribute data-mask="phone".
     */
    if (input === "phone" || input === "mobile") {
      const mask = (value.length > 14) ? "(99) 99999-9999" : "(99) 9999-9999";
      value = VMasker.toPattern(value, mask);
    }

    setParams({ ...params, [input]: value });
  };

  const handleOnSubmit = async e => {
    setNotification({});
    e.preventDefault();

    try {
      setBusy(true);
      const object = new Settings(params);
      const { data } = await object.save();
      setParams({ ...data.attributes });
      setNotification({
        message: "A configurações foram publicadas com sucesso!", type: "success"
      });
    } catch (err) {
      setNotification({ ...err.data, type: "danger" });
    } finally {
      setBusy(false);
    }
  };

  const viewProps = {
    params,
    busy,
    notification,
    handleOnChange,
    handleOnSubmit
  };

  return <SettingsPage {...viewProps} />;
};

export default SettingsController;
