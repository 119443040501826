import React, { useEffect } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import {
  CandidatesController,
  CategoriesController,
  ContactsController,
  DashboardController,
  LogoutController,
  SessionController,
  SettingsController,
  SlidersController,
  WorksController
} from "../controllers";

import { SessionProvider } from "../contexts";

const Routes = ({ history }) => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [history]);

  return (
    <Switch>
      <SessionProvider>
        <Route exact path="/candidates" component={CandidatesController} />
        <Route exact path="/categories" component={CategoriesController} />
        <Route exact path="/contacts" component={ContactsController} />
        <Route exact path="/" component={DashboardController} />
        <Route exact path="/logout" component={LogoutController} />
        <Route exact path="/session" component={SessionController} />
        <Route exact path="/settings" component={SettingsController} />
        <Route exact path="/sliders" component={SlidersController} />
        <Route exact path="/works" component={WorksController} />
      </SessionProvider>
    </Switch>
  );
};

export default withRouter(Routes);
