import React from "react";
import { Link } from "react-router-dom";
import { Container, Image, Row, Col } from "../../components";
import _ from "styled-components";

const Layout = ({ children }) => (
  <Wrapper>
    <Container>
      <Header>
        <Row>
          <Col col={6}>
            <Link to="/" title="Dashboard">
              <Image
                src={require("../../assets/logo.png")}
                alt="Engelar"
                style={{ width: 128 }}
              />
            </Link>
          </Col>
          <Col
            col={6}
            className="d-flex d-flex-row align-items-center justify-content-end">
            <Navbar>
              <Link to="/" className="mr-3">
                Início
              </Link>
              <a
                href="http://www.engelar.eng.br"
                target="_blank"
                rel="noopener noreferrer"
                className="mr-3">
                Website
              </a>
              <Link to="/logout">Logout</Link>
            </Navbar>
          </Col>
        </Row>
      </Header>
      {children}
      <Footer className="fixed-bottom">
        <Container>
          <div className="pl-3">
            <span>&copy; Engelar, 2019.</span>&nbsp;
            <a href="https://www.linkedin.com/in/bsilva0x87/" rel="noopener noreferrer" target="_blank">bsilva0x87</a>.
          </div>
        </Container>
      </Footer>
    </Container>
  </Wrapper>
);

const Wrapper = _.div`
  padding-bottom: 3.25rem;
`;

const Header = _.header`
  padding: 1rem
`;

const Navbar = _.nav`
  z-index: 100

  a {
    font-weight: bold;
    color: #384e70;
    text-transform: uppercase
  }
`;

const Footer = _.footer`
  padding: 1rem;
  background-color: white;
  font-size: 0.85rem;
`;

export default Layout;
