import React from "react";
import {
  Container,
  Layout,
  Title,
  Modal,
  Row,
  Col,
  Icon
} from "../../components";

import List from "./_list";

const Page = props => (
  <Layout>
    <Container>
      <div className="animated bounceInLeft">
        <Title>Candidatos ({props.objects.length})</Title>
        <p className="text-secondary mb-4 text-truncate">
          Nesta área você poderá gerenciar os candidatos criados pelo formulário
          de <strong>trabalhe conosco</strong>.
        </p>
      </div>
      <List {...props} />
      <Modal busy={props.busy} large>
        <h2 className="text-2 mb-0">Informações do Candidato</h2>
        <p className="text-secondary">
          A mensagem abaixo foi enviada pelo website{" "}
          <strong>engelar.eng.br</strong> em{" "}
          <strong>{props.params.created_at}</strong>.
        </p>

        <Row>
          <Col col={12} className="mb-2">
            <strong className="d-block">NOME COMPLETO</strong>
            <span className="text-secondary">{props.params.name}</span>
          </Col>
          <Col col={4} className="mb-2">
            <strong className="d-block">E-MAIL</strong>
            <a href={`mailto:${props.params.email}`}>{props.params.email}</a>
          </Col>
          <Col col={4} className="mb-2">
            <strong className="d-block">TELEFONE</strong>
            <a href={`tel:+55${props.params.phone}`}>{props.params.phone}</a>
          </Col>
          <Col col={4} className="mb-2">
            <strong className="d-block">DATA NASCIMENTO</strong>
            <span className="text-secondary">{props.params.birthdate}</span>
          </Col>
          <Col col={12} className="mb-2">
            <strong className="d-block">ENDEREÇO COMPLETO</strong>
            <span className="text-secondary">{props.params.address}</span>
          </Col>
        </Row>

        <hr />

        <Row>
          <Col col={12} md={6} className="mb-2">
            <strong className="d-block">RESUMO</strong>
            <div style={{ height: "96px", overflowY: "auto" }}>
              <p className="text-secondary">
                {props.params.resume || "Não informado."}
              </p>
            </div>
          </Col>
          <Col col={12} md={6} className="mb-2">
            <strong className="d-block">EXPERIÊNCIAS</strong>
            <div style={{ height: "96px", overflowY: "auto" }}>
              <p className="text-secondary">
                {props.params.experience || "Não informado."}
              </p>
            </div>
          </Col>
        </Row>

        <hr />

        <Row>
          <Col col={12} className="mb-2">
            <strong className="d-block">ANEXO</strong>
            <div class="d-inline-flex">
              <Icon name="md-attachment" />
              {!props.params.document ? (
                <span>Não há anexos.</span>
              ) : (
                <a href={`${props.params.document}`}>{props.params.document}</a>
              )}
            </div>
          </Col>
        </Row>
      </Modal>
    </Container>
  </Layout>
);

export default Page;
