import React from "react";
import PropTypes from "prop-types";

const RadioButton = ({ label, checked, name, value, onChange }) => (
  <label className="mr-4">
    <input
      type="radio"
      className="mr-2"
      {...{ label, checked, name, value, onChange }}
    />
    <span className="text-secondary">{label}</span>
  </label>
);

RadioButton.defaultProps = {
  label: "Text here...",
  checked: false
};

RadioButton.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default RadioButton;
